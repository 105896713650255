<template>
  <b-card id="platformSelector">
    <b-row class="d-block mb-2">
      <h1>{{ $t('Sellers') }} </h1>
      <h5 class="text-primary">
        {{ $t('selectVendor') }}
      </h5>
    </b-row>

    <b-row
      cols="12"
      class="m-1"
    >
      <b-col
        cols="7"
        class="mb-2"
      >
        <b-form-input
          v-model="buscar"
          :placeholder="$t('search')"
          @change="busqueda"
        />
      </b-col>
      <b-col
        cols="4"
        class="mr-2 justify-content-end"
      >
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
        />
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
      :show="show"
    >
      <b-row>
        <b-col
          v-for="vend in vendors"
          :id="vend.node.id"
          :key="vend.node.id"
          v-b-tooltip.hover.top="vend.node.vendorName"
          cols="2"
        >
          <b-card
            id="img"
            class="cursor-pointer text-center mb-2 embed-responsive embed-responsive-1by1"
            no-body
            @click="onRowSelected(vend)"
          >
            <b-img
              rounded="lg"
              :src="buildImageUrl(vend)"
              :style="`object-fit: cover; border:solid 1px`"
              class="embed-responsive-item "
            />
          </b-card>
          {{ vend.node.vendorName }} -
          {{ vend.node.name }}
          {{ vend.node.lastName }}
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>

import {
  BPagination,
  BCard,
  BCol,
  BRow,
  BImg,
  BOverlay,
  VBTooltip,
  BFormInput,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'

const background = require('@/assets/images/backend/background.png')
const noCover = require('@/assets/images/backend/nocover_1by1.png')

export default {
  components: {
    BFormInput,
    BImg,
    BPagination,
    BOverlay,
    BCard,
    BCol,
    BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      videojs: null,
      video: false,
      fileUrl: null,
      buscar: '',
      name: null,
      selected: null,
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      idOrig: null,
      vendors: null,
      currentParentFolder: null,
      backg: background,
      perPage: 12,
      userData: getUserData(),
      currentPage: 1,
      rows: null,
      show: false,

    }
  },

  mounted() {
    this.fetchData()
  },
  methods: {

    seleccionar() {
      this.$emit('vendor', this.selected)
    },
    onRowSelected(data) {
      this.selected = data.node
      this.seleccionar()
    },
    buildImageUrl(folder) {
      return folder.node.imageVendorUrl == null || folder.node.imageVendorUrl.length === 0
        ? noCover
        : folder.node.imageVendorUrl
    },

    fechasSplit(value) {
      try {
        const fecha = value
        const nueva = fecha.split(' ')[0]
        const format = nueva.split('-')
        return `${format[2].split('T')[0]}-${format[1]}-${format[0]}`
        // eslint-disable-next-line no-empty
      } catch (error) { }
      return ''
    },
    fetchData() {
      this.show = true
      axios
        .post('', {
          query: `
                    {
                    allVendors(client: "${this.userData.profile.client.id}",vendorName:"${this.buscar}") {
                        totalCount
                        edges {
                            node {
                            id
                            vendorName
                            name
                            lastName
                            vendorImage
                            imageVendorUrl
                            }
                        }
                    }
                }
          `,
        })
        .then(response => {
          messageError(response, this)

          const data = response.data.data.allVendors.edges
          this.vendors = data
          this.rows = response.data.data.allVendors.totalCount

          this.show = false
        }).catch(error => {
          console.log(error)
        })
    },

    busqueda() {
      this.fetchData()
    },

  },

}
</script>

<style lang="scss">
#avatar {
  width: 10rem;
  height: 5.5rem;
}

#contentsSelector .td {
  width: 50px;
  height: 100px;
}

#contentsSelector .folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#contentsSelector .dropContainer {
  border: 3px dashed;
}

#contentsSelector .card-width {
  width: 200px;
}

#contentsSelector .list-group-item {
  transition: all 1s;
}
</style>
